import { byteSize } from "@es/core/byteSize.ts";
import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

const DEFAULT_REDIRECT = "/";

/**
 * This should be used any time the redirect path is user-provided
 * (Like the query string on our login/signup pages). This avoids
 * open-redirect vulnerabilities.
 * @param {string} to The redirect destination
 * @param {string} defaultRedirect The redirect to use if the to is unsafe.
 */
export function safeRedirect(
  to: FormDataEntryValue | string | null | undefined,
  defaultRedirect: string = DEFAULT_REDIRECT
) {
  if (!to || typeof to !== "string") {
    return defaultRedirect;
  }

  if (!to.startsWith("/") || to.startsWith("//")) {
    return defaultRedirect;
  }

  return to;
}

export function getFormatedStorageUsed(bytes: number, precision = 2) {
  const oneKilobyte = Math.pow(10, 3);
  if (bytes === 0) {
    return `0 KB`;
  }
  if (bytes < oneKilobyte) {
    return `< 1 KB`;
  }

  const size = byteSize(bytes, { precision });
  return `${size.value}${size.unit}`;
}

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export async function fetchWithRetry(
  input: RequestInfo | URL,
  retries = 3,
  backoff = 300,
  init?: RequestInit
): Promise<Response> {
  for (let attempt = 0; attempt < retries; attempt++) {
    try {
      const response = await fetch(input, init);
      if (response.ok) {
        return response;
      }
      throw new Error(response.statusText);
    } catch (error) {
      if (error instanceof Error && error.name === "AbortError") {
        throw error; // If the request was aborted, do not retry
      }
      if (attempt < retries - 1) {
        const jitter = Math.random() * 100;
        const delay = backoff * Math.pow(2, attempt) + jitter;
        await new Promise((resolve) => setTimeout(resolve, delay));
      } else {
        throw error; // If all retries are exhausted, throw the error
      }
    }
  }
  throw new Error("Failed to fetch after multiple retries");
}
